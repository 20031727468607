<template>
  <div id="Tinder">
    <header class="imgination">
      <img src="logo_border.png" />
    </header>
    <SiteNav></SiteNav>

    <div class="container text-center">
      <canvas id="canvas"></canvas>

      <h1>
        <span class="brand-color-dark">Tinder</span> für
        <span class="brand-color-dark">Verkehr</span>sgesellschaft
      </h1>
      <p>Die nächste Affäre ist nur 1 Zug entfernt.</p>

      <NotAvailable></NotAvailable>

      <div class="section2">
        <div class="card">
          <div class="content"></div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
const confetti = require("canvas-confetti");

import SiteNav from "@/components/TheNavigation";
import NotAvailable from "@/components/NotAvailable";

export default {
  name: "Extension",
  components: {
    SiteNav,
    NotAvailable,
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {

    return 
    var myCanvas = document.getElementById("canvas");
    //document.appendChild(myCanvas);

    var myConfetti = confetti.create(myCanvas, {
      resize: true,
      useWorker: false,
    });

    var duration = 1 * 1000;
    var end = Date.now() + duration;

    (function frame() {
      // launch a few confetti from the left edge
      myConfetti({
        particleCount: 7,
        angle: 60,
        spread: 200,
        origin: { x: 0,y: 1.1 },
      });
      // and launch a few from the right edge
      myConfetti({
        particleCount: 7,
        angle: 120,
        spread: 200,
        origin: { x: 1,y: 1.1 },
      });

      myConfetti({
        particleCount: 5,
        angle: 100,
        spread: 300,
        origin: { x: 0.8,y: 1.1 },
      });

      myConfetti({
        particleCount: 5,
        angle: 40,
        spread: 300,
        origin: { x: 0.2,y: 1.1 },
      });

      // keep going until we are out of time
      if (Date.now() < end) {
        requestAnimationFrame(frame);
      }
    })();
  },
  computed: {},
};
</script>

<style scoped>
  canvas {
    position: fixed;
    bottom:0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    pointer-events: none;
  }

* {
  box-sizing: border-box; }

.card {
  width: 250px;
  height: 350px;
  position: relative;
  z-index: 2;
  border-radius: 20px; 
  margin-top: 400px;
  margin-bottom: 400px;
  }
  .card .content {
    width: 100%;
    height: 100%;
    background-color: #0c1016;
    border-radius: 15px; }
  .card::before, .card::after {
    content: "";
    width: 260px;
    height: 360px;
    display: block;
    position: absolute;
    z-index: -1;
    border-radius: 20px;
    background-image: linear-gradient(0, #ff00aa, #00FFF1);
    background-size: 100% 200%;
    background-position: center center;
    top: -5px;
    left: -5px;
    animation: spin 3000ms infinite alternate; }
  .card::after {
    filter: blur(125px); }

@keyframes spin {
  0% {
    background-position: top center; }
  100% {
    background-position: bottom center; } }
   .section2 {
    display: flex;
    justify-content: center;
    align-items: center;
  }
/*
  .section2 .card {
      height: 270px;
      width: 370px;
      position: relative;
      border-radius: 20px;
      box-shadow: 0 20px 35px rgba(0, 0, 0, 0.3);
      overflow: hidden;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -ms-border-radius: 20px;
      -o-border-radius: 20px;
  }

  .section2 .card:before {
      content: "";
      height: 500px;
      width: 700px;
      position: absolute;
      background: conic-gradient( #fd004c, #fe9000, #fff020, #3edf4b, #3363ff, #b102b7, #fd004c);
      background-size: 200%;
      left: -50%;
      top: -50%;
      animation: spin 3.5s infinite linear;
      -webkit-animation: spin 3.5s infinite linear;
  }

  @keyframes spin {
    100% {
        transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
    }
  }

  .section2 .card:after {
      content: "DEIN CONTENT";
      position: absolute;
      background-color: #1c1b29;
      box-shadow: inset 0, 0, 20px 5px green;
      height: 97%;
      width: 97%;
      top: 1.5%;
      left: 1.5%;
      border-radius: 15px;
      font-family: 'Poppins', sans-serif;
      color: #ffffff;
      font-size: 20px;
      letter-spacing: 6px;
      display: grid;
      place-items: center;
      -webkit-border-radius: 15px;
      -moz-border-radius: 15px;
      -ms-border-radius: 15px;
      -o-border-radius: 15px;
  } */
</style>